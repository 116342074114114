import React from "react";
import { Link } from 'react-router-dom';
import logo from "../assets/svg/logo-white.svg";

export default function Footer() {
        return (
            <footer className="bg-gray-box px-4 md:px-15">
                <div className="smaller-container mx-auto text-white pt-15 pb-8.5">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="lg:col-span-5 col-span-12">
                            <p>
                                <img src={logo} width="200" heigt="36" alt="blueventure-logo" />
                            </p>
                            <p className="opacity-70">Blue Venture is a Dive Travel agency that sells dive packages to dive destinations all over the world. For all levels of divers.</p>
                            <p className="opacity-70">We search &amp; select the best dive operators with highest level of security and best in class when it comes to service and equipment.</p>
                            <p className="opacity-70">Our products are currently sold and bookable through our worldwide network of trusted partners, such as KILROY Travels.</p>
                            <div>
                                <ul className="list-none opacity-70">
                                    <li className="uppercase inline-block mr-2.5">Follow us</li>
                                    <li className="inline-block mr-2.5 align-middle"><a target="_blank" rel="noreferrer" href="https://www.facebook.com/blueventure/">
                                    <i className="ti-facebook"></i>
                                    </a></li>
                                    <li className="inline-block mr-2.5 align-middle"><a target="_blank" rel="noreferrer" href="https://www.instagram.com/blueventure_diving/">
                                    <i className="ti-instagram"></i>
                                    </a></li>
                                    <li className="inline-block mr-2.5 align-middle"><a target="_blank" rel="noreferrer" href="https://www.twitter.com/blue_venture">
                                    <i className="ti-twitter-alt"></i>
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="lg:col-span-3 lg:ml-22 col-span-12 useful-links">
                            <h5 className="my-6 text-lg opacity-100">Useful links</h5>
                            <ul className="opacity-70">
                                <li><Link to="/company">Company</Link></li>
                                <li><Link to="/destinations">Destinations</Link></li>
                                <li><Link to="/salesOffice">Sales Offices</Link></li>
                                <li><Link to="/myBooking">My booking</Link></li>
                                <br></br>
                                <li><Link to="/help">Help &amp; Questions</Link></li>
                                <li><Link to="/contactUs">Contact us</Link></li>
                            </ul>
                        </div>
                        <div className="lg:col-span-3 lg:col-start-10 col-span-12 contact-us">
                            <h5 className="my-6 text-lg opacity-100">Contact with us</h5>
                            <ul className="opacity-70">
                                <li><i className="ti-mobile mr-3 align-middle"></i><a href="tel:+4540711937">+ 45 40 71 19 37</a></li>
                                <li><i className="ti-email mr-3 align-middle"></i><a href="mailto:support@blueventure.net">support@blueventure.net</a></li>
                            </ul>
                        </div>
                    </div>
                    <hr className="search-container mx-auto opacity-10 my-7.5"></hr>
                    <div className="search-container grid gap-y-8 lg:gap-0 mx-auto grid-cols-12">
                        <div className="lg:col-span-6 col-span-12">
                            <form>
                                <select className="language bg-white bg-opacity-5 text-gray-ccc cursor-pointer mr-3 rounded-3 pl-2.5 pr-2.5 h-7.5">
                                    <option value="English">English</option>
                                </select>
                                <select className="bg-white bg-opacity-5 text-gray-ccc cursor-pointer rounded-3 pl-2.5 pr-8.5 h-7.5">
                                    <option value="Euro">Euro</option>
                                </select>
                            </form>
                        </div>
                        <div className="terms lg:col-span-6 lg:col-start-8 col-span-12">
                            <ul className="list-none text-pointer opacity-50 text-right">
                                <li className="inline-block pr-3 border-r font-semibold"><Link to="/ToS">Terms and conditions</Link></li>
                                <li className="inline-block pr-3 border-r ml-3 font-semibold"><Link to="/ToS">Privacy &amp; Cookies</Link></li>
                                <li className="inline-block ml-3 font-semibold"><a href="https://divetravels.net" target="_blank" rel="noreferrer">© 2020 Travel Solutions</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer >
        )
}