import React from "react";
import SubImage from "../assets/subhero.jpg"

export default function Subhero(props) {

    return (
        <section className="bg-cover bg-no-repeat bg-center h-80 md:h-100" style={{ backgroundImage: "url(" + SubImage + ")" }}>
            <div className="container-gradient w-full h-full" >
                <div className="smaller-container mx-auto h-full flex items-center flex-col justify-center text-subHeroSecond font-bold uppercase text-white">
                    <span className="w-20 h-1 bg-yellow-span mx-auto block rounded-span mb-5"></span>
                    <h1 className="text-white font-extrabold">{props.title}</h1>
                </div>
            </div>
        </section>
    )
}