import React, {useState, useEffect, useRef} from "react";
import { Link } from 'react-router-dom';
import SubImage from "../../../assets/image_top_help_pages.jpeg"
import freshdesk from '../freshdesk';


export default function Subhero(props) {
    const [data, setData] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const searchInput = useRef(null)
    const searchField = useRef(null)
    const searchResults = useRef(null)

    function searchFreshdeskArticles(e) {
        const searchInput = e.target.value;

        searchResults.current.style.display = "block";
        if(searchInput === null) {
            searchResults.current.style.display = "none";
            searchResults.current.innerHTML = '';
            setData([]);
            return;
        }
        freshdesk('/api/v2/search/solutions?term=' + searchInput)
        .then(res => res)
        .then(
            (result) => {
                setData(result)
                setIsLoaded(true);
            },
            (error) => {
                console.log(error);
            }
        )
    }
    let array = [];
    if(isLoaded) {
        data.forEach(element => {
            if(element.title.toLowerCase().includes(searchInput.current.value.toLowerCase())) {
                array.push(element);
            }
        })
}

const resultsDiv = array.map(result => {
   return <Link key={result.id} to={`/support/article/${result.id}/${result.title}`} onClick={() =>  handleCleanUp(result.title)}><p className="text-red-500 pl-4 font-medium text-sm hover:bg-gray-background hover:text-gray-light transition-all capitalize py-2 mb-0">{result.title}</p></Link>;
})

function handleCleanUp(title) {
    searchResults.current.style.display = "none";
    searchInput.current.value = title;
}


useEffect(()=> {
    const closeSearch = (event) => {
        if(searchField.current && !searchField.current.contains(event.target)) {
            searchResults.current.style.display = "none";
        }
    }
    
    document.addEventListener("click", closeSearch);

    return () => {
        document.removeEventListener('click', closeSearch);
    }
}, [searchField])
    return (
        <section className="animate-scaleHero bg-cover bg-no-repeat bg-center h-80 md:h-100" style={{ backgroundImage: "url(" + SubImage + ")" }}>
            <div className="container-gradient w-full h-full" >
                <div className="smaller-container mx-auto h-full flex items-center flex-col justify-center font-bold uppercase">
                    <span className="animate-slideSearchIn w-20 h-1 bg-yellow-span mx-auto block rounded-span mb-5"></span>
                    <h1 className="animate-slideSearchIn text-white font-extrabold text-3xl lg:text-subHeroSecond">{props.title}</h1>
                    <div ref={searchField} className="animate-slideSearchInInput opacity-0 w-full px-6 md:px-0 md:w-2/4 relative input-group">
                        <input autoFocus={true} type="text" className="py-3.5 w-full text-base rounded-3" ref={searchInput} onInput={(e) => searchFreshdeskArticles(e)} placeholder="Start typing to search..."></input>
                        <i className="icon_search absolute right-12 md:right-4 h-full top-0 flex text-lg text-gray-nine items-center"></i>
                        <div ref={searchResults} className="search-results z-20 absolute w-full bg-white -mt-1">{resultsDiv}</div>
                    </div>
                </div>
            </div>
        </section>
    )
}