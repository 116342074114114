import React from 'react';
import Option from "./option"

export default function OptionList() {
    
    const cards = [
        {
            id: 1,
            title: "+ 1000 Customers",
            description: 'Id mea congue dictas, nec et summo mazim impedit. Vim te audiam impetus interpretaris, cum no alii option, cu sit mazim libris.',
            icon: 'pe-7s-medal',
        },
        {
            id: 2,
            title: "H24 Support",
            description: 'Id mea congue dictas, nec et summo mazim impedit. Vim te audiam impetus interpretaris, cum no alii option, cu sit mazim libris.',
            icon: 'pe-7s-help2',
        },
        {
            id: 3,
            title: "+ 575 Locations",
            description: 'Id mea congue dictas, nec et summo mazim impedit. Vim te audiam impetus interpretaris, cum no alii option, cu sit mazim libris.',
            icon: 'pe-7s-culture',
        },
        {
            id: 4,
            title: "Help Direct Line",
            description: 'Id mea congue dictas, nec et summo mazim impedit. Vim te audiam impetus interpretaris, cum no alii option, cu sit mazim libris.',
            icon: 'pe-7s-headphones',
        },
        {
            id: 5,
            title: "Secure Payments",
            description: 'Id mea congue dictas, nec et summo mazim impedit. Vim te audiam impetus interpretaris, cum no alii option, cu sit mazim libris.',
            icon: 'pe-7s-credit',
        },
        {
            id: 6,
            title: "Support via Chat",
            description: 'Id mea congue dictas, nec et summo mazim impedit. Vim te audiam impetus interpretaris, cum no alii option, cu sit mazim libris.',
            icon: 'pe-7s-chat',
        },
    ];

    const optionCards = cards.map(card => {
        return <Option key={card.id} {...card}/>
    })
        
        return (
            <div className=" bg-gray-background">
                <div className="smaller-container mx-auto pt-15 pb-8.5 px-4">
                    <span className="w-28 h-0.5 bg-gray-span mx-auto block"><em className="w-12 mx-auto block h-0.5 block bg-red-light"></em></span>
                    <h2 className="md:text-h3-banner text-center text-productTitle text-gray-light mt-6">Why choose Blue Venture</h2>
                    <p className="md:text-2xl text-xl text-center leading-1.4 text-gray-lighter">Cum doctus civibus efficiantur in imperdiet deterruiset</p>
                    <div className="grid grid-cols-12 lg:gap-8 gap-y-8 text-center">
                        {optionCards}        
                    </div>
                </div>
            </div>
    )
}