import React from "react";

export default function Aq(props) {
    let documents;

    if(props.attachments.length >= 1) {
        documents = <div className="mx-5 mb-8 border-t">
                    {props.attachments.map(attachment => {
                        return <a href={attachment.attachment_url} key={attachment.id} target="_blank" rel="noreferrer" className="border-solid rounded-3 border-2 border-red-light text-red-light font-semibold text-sm leading-none py-3 px-6 mt-4 mr-2 inline-block mx-auto hover:bg-blue-dark hover:text-white hover:border-blue-dark transition-all duration-300 ease-in-out"><i className="ti-download mr-2"></i>
                        {attachment.name}</a>
                    })}
                    </div>
    }

    return ( 
        <div id={props.id} className="get-help bg-white mb-3 shadow-md">
            <button onClick={(e) => { props.collapse(e) }} type="button" className="text-base font-semibold text-gray-nine text-left p-5 border-b w-full outline-none">{props.title}<i className="plusIcon pointer-events-none ti-plus align-middle float-right"></i></button>
            <div className="content" >
                <div className="px-5 pt-5 pb-2 mb-4" dangerouslySetInnerHTML={{ __html: props.description }}></div>
                {documents}
            </div>
        </div>
    )
}