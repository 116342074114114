import React from "react";

export default function StaffPicks(props) {
    return (
        <div className="destination">
            <figure className="rounded-3 relative w-full h-full overflow-hidden">
                <div className="score absolute bg-blue-dark text-white rounded-3 top-3.75 right-3.75 z-20 pointer-events-none">
                    <p className="p-2.5 text-sm leading-none font-semibold">From {props.price} EUR</p>
                </div>
                <img className="w-full transform scale-110 hover:scale-100 transition-all duration-300 ease-in-out cursor-pointer" src={props.background} alt="Destinations Images" />
                <div className="info absolute w-full bottom-0 left-0 text-white px-5 pt-6 pb-1.25 pointer-events-none">
                    <small className="text-xs font-medium uppercase">{props.title}</small>
                    <h3 className="text-lg leading-tight font-medium mb-2">{props.location}</h3>
                </div>
            </figure>
        </div>
    );
}
