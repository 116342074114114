import React, {useState, useEffect} from 'react';
import Option from "./option"
import { Link } from 'react-router-dom';
import freshdesk from '../../freshdesk';

export default function OptionList() {
    const [data, setData] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        freshdesk('/api/v2/solutions/categories/5000022588/folders')
        .then(res => res)
        .then(
            (result) => {
                setData(result);
                setIsLoaded(true);
            },
            (error) => {
                console.log(error);
            }
        )
    }, [])

    const icons = [
            'pe-7s-help2',
            'pe-7s-download',
            'pe-7s-global',
            'pe-7s-plane',
            'pe-7s-credit',
            'pe-7s-note2',
    ];

    const optionCards = data.map((card,index) => {
        card.icon = icons[index];
        return <Link className="col-span-12 lg:col-span-4" key={card.id} to={`/support/folder/${card.id}/${card.name}`}><Option key={card.id} {...card}/></Link>
    }, [icons])
        
        return (
            <div className="bg-gray-background">
                <div className="smaller-container mx-auto pt-15 pb-8.5 px-4">
                    <span className="w-28 h-0.5 bg-gray-span mx-auto block"><em className="w-12 mx-auto block h-0.5 block bg-red-light"></em></span>
                    <h2 className="md:text-h3-banner text-center text-productTitle text-gray-light font-semibold mt-6">Select a topic</h2>
                    <p className="text-base text-center leading-1.4 text-gray-lighter">We try to answer and cover the most frequent question we meet regarding dive travels.</p>
                    <div className="grid grid-cols-12 lg:gap-8 gap-y-8 text-center">
                        { isLoaded ? optionCards : ''}        
                    </div>
                </div>
            </div>
    )
}