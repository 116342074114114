import React from "react";
import { Link } from 'react-router-dom';
import bg_call_section from "../assets/images/bg_call_section.jpg";

export default function CallSection() {
    return (
        <div className="w-full bg-cover bg-no-repeat bg-center md:py-48 py-10 bg-fixed" style={{ backgroundImage: "url(" + bg_call_section + ")" }}>
            <div className="smaller-container grid grid-cols-12 mx-auto">
                <div className="text-white bg-gray-box p-11.5 max-w-1/2 lg:col-start-8 lg:col-end-13 col-start-2 col-end-12">
                    <h3 className="lg:text-h3-banner text-subHeroHeader">Enjoy a GREAT travel with us</h3>
                    <p>Ius cu tamquam persequeris, eu veniam apeirian platonem qui, id aliquip voluptatibus pri. Ei mea primis ornatus disputationi. Menandri erroribus cu per, duo solet congue ut.</p>
                    <Link to="/company" className="border-btn cursor-pointer inline-block no-underline py-3.75 px-7.5 bg-red-light text-center rounded-3 leading-none font-semibold hover:bg-blue-dark transition-all duration-300 ease-in-out">Read more</Link>
                </div>
            </div>
        </div>
    )
}

