import React from "react";


export default function SecondNavbar() {
    return (
        <nav className="sticky border-b">
            <div className="smaller-container py-4 px-4 flex justify-between mx-auto">
                <div className="font-bold self-center">From 630 EUR per person</div>
                <div className="text-white">
                    <a href="destionations"
                        className="cursor-pointer inline-block no-underline py-3.75 px-7.5 bg-red-light text-center rounded-3 leading-none font-semibold">Find
                        Download broucher</a>
                </div>
            </div>
        </nav>
    )
}