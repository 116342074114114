import React from "react"

export default function Diver(props) {
    return(
        <div className="text-center w-full px-8 mb-5">
            <div className="h-64 w-64 mx-auto mb-6">
                <img className="h-full rounded-full" src={props.image} alt="Diver"/>
            </div>
            <h6 className="uppercase">{props.name}</h6>
            <a href="www.amustard.com" target="_blank" className="">{props.portofolio}</a>
            <hr className="my-5" />
            <p>{props.description}</p>
            <div className="socials">
                <ul className="list-none">
                    <li className="inline-block mr-1 align-middle"><a href="www.facebook.com" target="_blank"><i className="ti-facebook"></i></a></li>
                    <li className="inline-block mr-2 align-middle"><a href="www.twitter.com" target="_blank"><i className="ti-twitter-alt"></i></a></li>
                    <li className="inline-block align-middle"><a href="www.instagram.com" target="_blank"><i className="ti-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    )
}