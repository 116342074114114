import React, {useRef} from 'react'
import { MapContainer as LeafletMap, TileLayer, Marker, useMap, Popup} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import L from 'leaflet';

import icon from '../../../assets/svg/map-pin.svg';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import expand from "../../../assets/svg/expand.svg"
import compress from "../../../assets/svg/compress.svg"

export default function Map(props) {
    const expandMap = useRef(null)
 
    // Defining corners so user cant drag map out of view
    const corner1 = L.latLng(-90, -200)
    const corner2 = L.latLng(90, 200)
    const bounds = L.latLngBounds(corner1, corner2)
    var expanded = false;

    var DefaultIcon = L.icon({
        iconSize: [25,41],
        iconAnchor: [12.5,41],
        iconUrl: icon,
        shadowUrl: iconShadow,
        popupAnchor: [-150,150]
    });
    
    L.Marker.prototype.options.icon = DefaultIcon;
    var firstSalesOffice = props.salesOffices[0][0];

    L.Control.Expand = L.Control.extend({
        onAdd: function(map) {
            var button = L.DomUtil.create('button');

            button.className = "bg-white p-3.75 rounded-3 text-sm cursor-pointer"
            button.style.backgroundImage = "url('" + expand + "')";
            button.id = "expandButton"
            button.style.backgroundRepeat = "no-repeat"
            button.style.backgroundPosition = "center center"
            
            button.onclick = function() {
                if(!expanded) {
                    button.style.backgroundImage = "url('" + compress + "')";
                    expandMap.current.style.height = "100vh";
                    expanded = true;
                } else {
                    button.style.backgroundImage = "url('" + expand + "')";
                    expandMap.current.style.height = "24rem";
                    expanded = false;
                }
                map.invalidateSize()
            }

            return button;
        },
    
        onRemove: function(map) {
        }
    });
    
    L.control.expand = function(opts) {
        return new L.Control.Expand(opts);
    }

    function ExpandMapControl() {
        const map = useMap()

        map.addEventListener("keydown", (e) => {
            if(e.originalEvent.key === "Escape") {
                expandMap.current.style.height = "24rem";
                document.querySelector("#expandButton").style.backgroundImage = "url('" + expand + "')";;
                expanded = false;
            }
        })

        L.control.expand({ position: 'topright' }).addTo(map);
        L.control.zoom({
            position: 'topright'
        }).addTo(map);
        return null
    }

    return (
        <div id="map" ref={expandMap} className="relative salesOfficeMap h-96 z-10">
            <LeafletMap className="map absolute top-0 bottom-0 markercluster-map" style={{ width: '100%', height: '100%' }} zoom={13} center={firstSalesOffice.PlusCode} minZoom={2} scrollWheelZoom={true} zoomControl={false}
                maxBoundsViscosity={1.0}
                maxBounds={bounds}
                > 
                <ExpandMapControl />
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                />
                <MarkerClusterGroup maxClusterRadius={100} showCoverageOnHover={false}>
                    {props.salesOffices.map((location,index) => (
                        <Marker position={location[index].PlusCode}
                        key={location[index].ID}
                        id={location[index].ID}
                        className={'marker'}>  
                         <Popup
                            width={240}
                            maxHeight={322}
                            className="popup"
                            closeButton={true}

                            >
                                <div className="h-36">
                                    <img src={location[index].UnsplashCollectionID ? "https://source.unsplash.com/collection/" + location[index].UnsplashCollectionID + "/800x300" : "https://source.unsplash.com/collection/59438018/800x300"}alt="City from unsplash" className="object-cover h-full"/>
                                </div>
                                <div className="px-5 pt-5 pb-3">
                                    <h3 className="text-base mb-2 text-red-destination">{location[index].City}</h3>
                                    <p className="font-medium text-gray-400 uppercase text-xm">{location[index].Realname}</p>
                                </div>
                                <div className="px-5 pt-2 pb-1">
                                    <a href={`https://www.google.dk/maps/place/${location[index].PlusCode}`} target="_blank" rel="noreferrer" ><p className="text-red-destination pb-2 font-semibold"><i className="icon_compass mr-3 align-inherit"></i>Get directions</p></a>
                                    <a href={`tel:${location[index].Phone}`} target="_blank" rel="noreferrer" ><p className="text-red-destination pb-2 font-semibold"><i className="icon_phone mr-3 align-inherit"></i>{location[index].Phone}</p></a>
                                    <a href={`mailto:${location[index].Email}`} target="_blank" rel="noreferrer" ><p className="text-red-destination pb-2 font-semibold"><i className="icon_mail mr-3 align-inherit"></i>Send email</p></a>
                                </div>
                            </Popup>   
                        </Marker>    
                    ))}
                </MarkerClusterGroup>
            </LeafletMap>
        </div>
    )
}