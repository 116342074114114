import React from "react"

export default function option(props) {
    return (
        <div className="col-span-12 lg:col-span-4 option bg-white pt-10 pb-7.5 px-7.5 transform hover:-translate-y-2.5 shadow-icon">
            <i className={`text-optionIcons text-green-price ${props.icon}`}></i>
            <h4 className="text-lg mb-2 text-gray-light">{props.title}</h4>
            <p className="text-base mb-0 text-gray-options">{props.description}</p>
        </div>
        )
}
