import React, {useEffect} from 'react';
import {Link} from 'react-router-dom'

import logo from "../../assets/svg/logo-dark.svg";

export default function MyBooking(props) {
    useEffect(() => {
        props.setIsMyBooking(true);
        document.title = "My Booking - Blue Venture"
     }, [props]);
    return ( 
        <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-5 relative">
                <div className="py-5 px-24 border-b">
                    <a href="/">
                        <img src={logo} width="170" heigt="36" alt="blueventure-logo" />
                    </a>
                </div>
                <div className="px-24 py-14 myBooking">
                    <Link className="font-medium text-gray-500" to="/" onClick={() => props.setIsMyBooking(false)}><i className="arrow_left align-middle"></i> Back</Link>
                    <h1 className="font-bold text-3xl mb-10 text-gray-900 mt-8 leading-9">Sign in to access your booking &amp; voucher</h1>
                    <form>
                        <label htmlFor="lastname" className="text-gray-700 font-medium leading-5 text-sm">Last name on booking</label>
                        <input type="text" name="lastname" id="lastname" placeholder="Enter your last name" className="w-full border rounded-3 shadow-sm p-2 mb-4"></input>
                        
                        <label htmlFor="lastname" className="text-gray-700 font-medium leading-5 text-sm">Booking number</label>
                        <input type="text" name="lastname" id="lastname" placeholder="Enter booking number from voucher" className="w-full border rounded-3 shadow-sm p-2 mb-6"></input>
                        
                        <input type="submit" value="View your booking" className="w-full text-white bg-blue-600 py-3 px-4 rounded-3 font-semibold cursor-pointer"></input>
                    </form>
                    <div className="mt-10 text-center">
                        <p className="text-gray-400 font-medium leading-5 text-sm mb-1">Can't find your booking?</p>
                        <Link to="/salesOffice" className="text-blue-600 text-xs leading-4 font-semibold" onClick={() => props.setIsMyBooking(false)}>Contact your local sales offices.</Link>
                    </div>
                    <span className="absolute right-1/2 transform translate-x-1/2 bottom-10 text-gray-400 font-semibold text-sm leading-5">&copy; 2021 Travel Solutions</span>
                </div>
            </div>
            <div className="col-span-7 h-screen hidden lg:block bg-cover bg-no-repeat bg-right" style={{ backgroundImage: "url(https://aljedakxem.cloudimg.io/v7/sandbox.blueventure.net/panagea/_assets/images/pages/login/login-img.jpg?func=crop&gravity=auto&sharpen=1&h=900&w=1440)" }}>
            </div>
        </div>
    )
}