import React from "react";
import Img, { CloudimageProvider } from "react-cloudimage-responsive"

export default function Destination(props) {
    const cloudimageConfig = {
        token: "aljedakxem",
        baseURL: "",
    };

    return (
        <div className="destination cursor-pointer h-full">
            <figure className="rounded-3 relative w-full h-full overflow-hidden">
                <div className="score absolute bg-blue-dark text-white rounded-3 top-3.75 right-3.75 z-10">
                    <p className="p-2.5 text-sm leading-none font-semibold"># {props.number}</p>
                </div>
                <CloudimageProvider config={cloudimageConfig}>
                    <Img src={props.image} style={{}} ratio={0.8} alt="Destinations Images" id="destinationImage"/>
                </CloudimageProvider>
                <div className="info absolute w-full bottom-0 left-0 text-white px-5 pt-6 pb-1.25 pointer-events-none">
                    <small className="text-xs font-medium uppercase">{props.Region}</small>
                    <h3 className="text-lg leading-tight font-medium mb-2">{props.Country} · {props.Destination}</h3>
                </div>
            </figure>
        </div>
    );
}
