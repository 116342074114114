import React, {useState, useEffect, useRef} from 'react'

import SalesOffice from './salesoffice'
import arrow from "../../../assets/svg/arrow.svg"

var salesOfficesArray = [];
export default function SalesOfficesList(props) {
    const [searching, setSearching] = useState(false);
    const [searchingAll, setSearchingAll] = useState(true);
    const [salesOfficesToShow, setSalesOfficesToShow] = useState([])
    const [typing, setTyping] = useState([])
    const noResults = useRef(null)
    const searchInput = useRef(null)
    let flag = false;
    
    let arrayOfCountries = []
    const salesOfficesList = props.salesOffices.map((salesoffice,index) => {
        arrayOfCountries.push(salesoffice[index].Country)
        return <SalesOffice key={salesoffice[index].ID} {...salesoffice[index]}/>
    })

    arrayOfCountries = [...new Set(arrayOfCountries)]
  
    // function for searching in salesOffices
    async function getSalesOffices(e) {
        let salesOfficeSearch = e.target.value;
        // empty array to hold all the results we get from the search
        let emptyArray = []

        // If input field is empty show every sales office and hide dropdown list
        if(salesOfficeSearch.length === 0 && salesOfficesArray.length === 0) {
            setSearching(false)
            noResults.current.style.display = "none";
            return;
        }
        // All the JSON fields we search in
        let searchArray = ["Realname", "Address", "Zip", "City", "Country"];
        
        // Filter for salesOffices;
        const filterSalesOfficces = (arr, query, searchArray) => {
            var filteringCountry = arr.filter(el => {
                let objectKey =  Object.keys(el)
                return el[objectKey][searchArray].toLowerCase().indexOf(query.toLowerCase()) > -1
            },searchArray)

            // If no match show nothing
            if (filteringCountry.length === 0) {
                setSalesOfficesToShow([])
            }

            return filteringCountry
        }
        
        // Only search in the selected countries
        if(salesOfficesArray.length >= 1) {
           var filtered = props.salesOffices.filter((searchInput,index) => salesOfficesArray.includes(searchInput[index].Country));
           searchArray.forEach(jsonField => {
                emptyArray.push(filterSalesOfficces(filtered, salesOfficeSearch, jsonField))
            });
        }
        else {
            // Call filter for salesoffices for each of the JSON fields
            searchArray.forEach(jsonField => {
                // Pushing every array the filter returns into the empty array
                emptyArray.push(filterSalesOfficces(props.salesOffices, salesOfficeSearch, jsonField))
            });
        }

        mapOverUniqueArray(emptyArray);
   
    }

    async function countryFilter(e) {
        let checkBoxes = document.querySelectorAll(".form-tick");
        let allCountries = document.querySelector("#allCountries");

        if(e.target.checked) {
            salesOfficesArray.push(e.target.value)
        } 
        else {
            const index = salesOfficesArray.indexOf(e.target.value);
            if (index > -1) {
                salesOfficesArray.splice(index, 1);
            }
        }

        if(e.target.value !== 'All') {
            allCountries.checked = false;
            setSearchingAll(false);
        }

        if(e.target.value === 'All' && e.target.checked === true) {
            checkBoxes.forEach(element => {
                if(element.value !== 'All') {
                    element.checked = false;
                }
            })
            setSearching(false)
            setSearchingAll(true);
            salesOfficesArray = []
            return;
        }
        
        if (salesOfficesArray.length === 0) {
            setSearching(false)
            return;
        }
        
        var filtered = props.salesOffices.filter((searchInput,index) => salesOfficesArray.includes(searchInput[index].Country));

        mapOverUniqueArray(filtered);

    }

    function mapOverUniqueArray(selectedCountries) {
        let arrayOfSalesOfficesObjects = []

        //merging arrays and remove duplicates
        var arrayOfUniqueSalesOffices = [...new Set([].concat.apply([], selectedCountries))];

        if(arrayOfUniqueSalesOffices.length === 0) {
            noResults.current.style.display = "block";
        } else {
            noResults.current.style.display = "none";
        }

        arrayOfUniqueSalesOffices.map(salesoffice => {
            let objectKey =  Object.keys(salesoffice)
            let salesOfficeObject = <SalesOffice key={salesoffice[objectKey].ID} {...salesoffice[objectKey]}/>
            arrayOfSalesOfficesObjects.push(salesOfficeObject)
            return setSalesOfficesToShow(arrayOfSalesOfficesObjects);
        })

        setSearching(true);
    }

    function resetSearchSalesOfficesOptions() {
        let checkBoxes = document.querySelectorAll(".form-tick");
        let allCountries = document.querySelector("#allCountries");
        checkBoxes.forEach(element => {
            if(element.value !== 'All') {
                element.checked = false;
        }
        });
        allCountries.checked = true;
        noResults.current.style.display = "none";
        searchInput.current.value = '';
        setSearchingAll(true);
        setSearching(false)
    }

    function toggleCheckboxesVisibilty() {
        const checkboxes = document.querySelector("#checkboxes");
        const arrow = document.querySelector("#arrow")
        const filterButton = document.querySelector("#country-filter");
        if (!flag) {
            flag = true;
            checkboxes.style.display = "block";
            arrow.style.transform = "rotate(180deg)";
            filterButton.classList.add("filter-country-border")
        } else {
            flag = false;
            checkboxes.style.display = "none";
            filterButton.classList.remove("filter-country-border")
            arrow.style.transform = "rotate(0)";
          }
    }

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                flag = true;
                toggleCheckboxesVisibilty()
          }
        };
        const handleClick = (event) => {
            if(!event.target.classList.contains("handleClick")) {
                flag = true;    
                toggleCheckboxesVisibilty()
            }
        }
        window.addEventListener('keydown', handleEsc);
        document.querySelector("#salesOfficeContainer").addEventListener('click', handleClick);
        
        return () => {
        window.removeEventListener('click', handleClick);
          window.removeEventListener('keydown', handleEsc);
        };
      }, []);

    return (
        <div className="container mx-auto md:pt-15 pt-7.5 pb-8.5 px-4">
            <p className="font-semibold text-gray-nav mb-1">500+ products available</p>
            <div className="md:flex md:justify-between border-b pb-5 pt-1">
                <div>
                    <h1 className="text-4xl text-gray-destination font-bold">Sales offices</h1>
                </div>
                <div className="self-center flex flex-col md:flex-row">
                    <div className="relative mb-4 md:mb-0">
                        <span className="ti-search absolute top-1/2 left-5 transform -translate-y-1/2"></span>
                        <input ref={searchInput} type="text" id="salesOfficeSearch" autoComplete="off" placeholder="Search" onInput={(e) => {getSalesOffices(e); setTyping(e.target.value)}} className="search-button border border-gray-destination w-full focus:shadow-searchField focus:border-transparent mr-5 py-2.5 border-btn font-medium text-base"></input>
                    </div>
                    <div id="country-container" className="relative">
                        <div id="country-filter" tabIndex={-1} onClick={toggleCheckboxesVisibilty} className="handleClick border md:ml-5 border-btn leading-none appearance-none py-3.5 border-gray-destination text-gray-900 px-6 text-base cursor-pointer">
                           <p className="mb-0 pointer-events-none flex font-semibold">Country<span className={searchingAll ? "text-gray-400 pointer-events-none ml-1 mr-4" : "mr-4 ml-1 text-white"}>(All)</span><img src={arrow} id="arrow" alt="Select country arrow svg" className="self-center ml-auto pointer-events-none"/></p>
                        </div>
                        <div id="checkboxes" className="handleClick bg-white hidden border text-sm absolute shadow-lg rounded-3 left-0 md:right-0 mt-1 font-medium p-2 z-50">
                            <label className="handleClick block p-2 border-b">
                            <input id="allCountries" type="checkbox" defaultChecked onClick={(e) => countryFilter(e)} className="handleClick form-tick appearance-none h-4 w-4 mr-2 align-middle border border-gray-300 rounded-3 checked:bg-blue-600 checked:border-transparent focus:outline-none" value="All" />All</label>
                            {arrayOfCountries.map(country => {
                                return <label key={country} className="handleClick block p-2">
                                    <input type="checkbox" onClick={(e) => countryFilter(e)} className="handleClick form-tick appearance-none h-4 w-4 mr-2 align-middle border border-gray-300 rounded-3 checked:bg-blue-600 checked:border-transparent focus:outline-none" value={country} />{country}</label>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        <div ref={noResults} className="bg-gray-50 hidden text-center py-10 mt-10">
            <h2 className="text-2xl leading-7 font-bold text-gray-900 mb-4">Sorry, there's no matching results :-(</h2>
            <p className="text-xs leading-4 font-semibold uppercase tracking-wider text-gray-500">We could not find any sales offices that matches your search "{typing}"</p>
            <button onClick={() => resetSearchSalesOfficesOptions()} className="bg-blue-600 py-3 px-4 text-white rounded-3 text-sm font-semibold">Show all sales offices</button>
        </div>
         <div className="grid grid-cols-12 gap-y-12 w-full mt-15">
            {searching ? salesOfficesToShow : salesOfficesList}
        </div>
        </div>
    )
}