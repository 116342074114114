import React from 'react';
import Img, { CloudimageProvider } from "react-cloudimage-responsive"

export default function Destionation(props) {
    
    const cloudimageConfig = {
        token: "aljedakxem",
        baseURL: "",
        lazyLoading: false,
    };

    return (
            <div className="map-card grid grid-cols-12 mb-10 cursor-pointer border-b" id={props.DestID} onClick={() => { props.onChange(props.PlusCode)}}>
                <div className="col-span-4 relative h-full rounded-span overflow-hidden">
                    <CloudimageProvider config={cloudimageConfig}>
                        <Img src={props.image} style={{}} ratio={1.3} alt="Destinations Images" id="destinationImage"/>
                    </CloudimageProvider>
                </div>
                <div className="col-span-8 col-start-6 row-span-2 md:row-span-1 bg-white relative">
                    <p className="mb-2 font-medium">{props.Country}</p>
                    <p className="map-card-destination mb-2 font-semibold duration-300 ease-out text-base">{props.Destination}</p>
                    <p className="mb-2">12 packages<span className="mx-2">&#8226;</span>9 supplements</p>
                    <div className="md:self-end md:absolute md:bottom-0">
                        <p className="mb-0 mr-8 inline-block"><i className="icon_star text-blue-blueventure mr-3"></i>4.7 <span className="text-gray-nav">(divers)</span></p>
                        <p className="mb-0 inline-block"><i className="icon_star text-blue-blueventure mr-3"></i>5.0 <span className="text-gray-nav">(non-divers)</span></p>
                    </div>
                </div>
                <div className="col-start-1 col-end-4">
                    <p className="my-5 font-bold text-base">From € 398</p>
                </div>    
            </div>
    )
}