import React, {useState, useEffect} from "react";
import MapOfDestinations from "./components/map";
import DestinationList from "./components/destinationList/destinationList";


export default function Destinations(props) {
    // Location used to setView in map component
    const [location, setLocation] = useState([10,10]);
    // PreviousLocation to make map draggable after using the setView function
    const [previousLocation, setPreviousLocation] = useState([10,10]);
    // All destinations to show on list and markers on map
    const [destination, setDestination] = useState([]);
    // Filter in destinations when dragging aroung on map
    const [destionationsToShow, setDestinationsToShow] = useState([]);

    
    useEffect(() => {
        document.title = "Destinations - Blue Venture";

        var OpenLocationCode = require('open-location-code').OpenLocationCode;
        var openLocationCode = new OpenLocationCode();

        async function getDestinations() {
            const response = await fetch('https://blueventure.net/rest/destinations/all/1000');
            const data = await response.json();
            const arrayOfObj = Object.entries(data).map((e) => ( { [e[0]]: e[1] } ));
            arrayOfObj.splice(arrayOfObj.length - 5, 5);
            arrayOfObj.forEach((element,index) => {
                element[index].PlusCode = convertPlusCode(element[index].PlusCode);
            });
            setDestination(arrayOfObj);
        }

        function convertPlusCode(newLocation) {
            let coord = openLocationCode.decode(newLocation);
            let latLng = [coord.latitudeCenter,coord.longitudeCenter];
            return latLng;
        }
        getDestinations();
    }, [props])


    // Change location state to the clicked on destinations location
    function changeLocation(newLocation) {
        setLocation(newLocation);
    }

    // Set previousLocation to location
    function changePreviousLocation(location) {
        setPreviousLocation(location);
    }

    // Filter destinations to show in list
    function setDestinations(showDestinations) {
        // Remove duplicates because of the MarkerClusterGroup
        let unique = [...new Set(showDestinations)];
        setDestinationsToShow(unique);
    }

    return (
        <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-5 h-screen overflow-hidden">
                <DestinationList onChange={changeLocation} destinations={destination} showDestinations={destionationsToShow}/>
            </div>
            <div className="col-span-12 lg:col-span-7">
                <MapOfDestinations location={location} previousLocation={previousLocation} onChange={changeLocation} onNewLocation={changePreviousLocation} destinations={destination} setDestination={setDestinations}/>
            </div>
        </div>
    );
}