import React, { useState, useEffect} from "react";
import SubHero from "../../common/subHero";

export default function ToS() {
    const [terms, setTerms] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        document.title = "ToS - Blue Venture"
     }, []);

    useEffect(() => {
        fetch("https://blueventure.net/rest/terms")
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setTerms(result["0"]);

                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    return (
        <div className="bg-white">
            {!isLoaded ? error : ''}
            <SubHero title="Terms &amp; Conditions"/>
            <div className="bg-white pt-15 pb-10">
                <div className="bg-gray-background card-container mx-auto px-6 pt-10 shadow-lg">
                <h4 className="text-lg text-black font-semibold ">Terms &amp; Conditions</h4>
                <p className="text-gray-400 font-semibold">Updated {terms.date}</p>
                <div className="" dangerouslySetInnerHTML={{ __html: terms.DisclaimerText }}>
                </div>
            </div>
            </div>
        </div>
    )
}