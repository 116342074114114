import React from "react";
import Diver from "./diver";
import AboutImage from "../../../../assets/about_1.jpg"
import OwlCarousel from 'react-owl-carousel';


export default function DiverList() {

    const diverList = [
        {
            id: 1,
            name: "Alex Mustard",
            portofolio: 'www.amustard.com',
            description: 'Dr. Alexander Mustard MBE, is marine biologist, underwater photographer and author. He is a proud generalist underwater image-maker, enjoying equally photographing blue whales at the equater, nudibranchs in the Artic, recording shipwrecks and people in the sea.',
            image: AboutImage,
            facebook: 'Link',
            twitter: 'Link',
            instagram: 'Link',
        },
        {
            id: 2,
            name: "Alex Mustard",
            portofolio: 'www.amustard.com',
            description: 'Dr. Alexander Mustard MBE, is marine biologist, underwater photographer and author. He is a proud generalist underwater image-maker, enjoying equally photographing blue whales at the equater, nudibranchs in the Artic, recording shipwrecks and people in the sea.',
            image: AboutImage,
            facebook: 'Link',
            twitter: 'Link',
            instagram: 'Link',
        },
        {
            id: 3,
            name: "Alex Mustard",
            portofolio: 'www.amustard.com',
            description: 'Dr. Alexander Mustard MBE, is marine biologist, underwater photographer and author. He is a proud generalist underwater image-maker, enjoying equally photographing blue whales at the equater, nudibranchs in the Artic, recording shipwrecks and people in the sea.',
            image: AboutImage,
            facebook: 'Link',
            twitter: 'Link',
            instagram: 'Link',
        },
        {
            id: 4,
            name: "Alex Mustard",
            portofolio: 'www.amustard.com',
            description: 'Dr. Alexander Mustard MBE, is marine biologist, underwater photographer and author. He is a proud generalist underwater image-maker, enjoying equally photographing blue whales at the equater, nudibranchs in the Artic, recording shipwrecks and people in the sea.',
            image: AboutImage,
            facebook: 'Link',
            twitter: 'Link',
            instagram: 'Link',
        },
        {
            id: 5,
            name: "Alex Mustard",
            portofolio: 'www.amustard.com',
            description: 'Dr. Alexander Mustard MBE, is marine biologist, underwater photographer and author. He is a proud generalist underwater image-maker, enjoying equally photographing blue whales at the equater, nudibranchs in the Artic, recording shipwrecks and people in the sea.',
            image: AboutImage,
            facebook: 'Link',
            twitter: 'Link',
            instagram: 'Link',
        },
        {
            id: 6,
            name: "Alex Mustard",
            portofolio: 'www.amustard.com',
            description: 'Dr. Alexander Mustard MBE, is marine biologist, underwater photographer and author. He is a proud generalist underwater image-maker, enjoying equally photographing blue whales at the equater, nudibranchs in the Artic, recording shipwrecks and people in the sea.',
            image: AboutImage,
            facebook: 'Link',
            twitter: 'Link',
            instagram: 'Link',
        }
    ]

    let state = {
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1140: {
                items: 3,
            },
        },
    }
    
    const divers = diverList.map(diver => {
        return <Diver key={diver.id} {...diver}/>
    });

    return (
        <div className="smaller-container mx-auto px-4">
              <h2 className="md:text-h3-banner text-center text-productTitle text-gray-light mt-6">Team Divers</h2>
              <p className="text-center md:px-8 px-0">At fourth element we work with some of the leading professionals in the diving industry; testing, advising and enjoying the fourth element experience. From technical divers to scientists, freedivers and conservationists, these individuals are the forefront of exploration, discovering new techniques and pushing the boundaries of our underwater world.</p>
              <OwlCarousel items={3}
                className="owl-theme"
                margin={8}
                responsive={state.responsive}>
                {divers}
            </OwlCarousel>
        </div>
    )
}