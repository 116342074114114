import React, {useState,useEffect} from "react"
import hoursImage from "../../../assets/img_business_hours.jpg"
import freshdesk from '../freshdesk';

export default function OpeningHours() {
    const [data, setData] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        freshdesk('/api/v2/business_hours/')
        .then(res => res)
        .then(
            (result) => {
                setData(result[0]);
                setIsLoaded(true);
            },
            (error) => {
                console.log(error);
            }
        )
    }, [])
    
    return( 
        <div>
            <div className="smaller-container mx-auto pt-15 pb-8.5 px-4">
                <span className="w-28 h-0.5 bg-gray-span mx-auto block"><em className="w-12 mx-auto block h-0.5 block bg-red-light"></em></span>
                <h2 className="md:text-h3-banner font-semibold text-center text-productTitle text-gray-light mt-6">We're ready to help</h2>
                <p className="text-base text-center leading-1.4 text-gray-lighter">Don't hesitate to contact us with your questions</p>
            <div className="grid grid-cols-12 gap-y-5 md:gap-y-0">
                <div className="md:col-span-6 col-span-12"><img src={hoursImage} alt="Hours"/></div>
                <div className="md:col-span-5 md:col-start-8 col-span-12">
                    <h4 className="text-2xl font-semibold">{ isLoaded ? data.description : 'Business Opening Hours' }</h4>
                    <div className="business-hours">
                        <ul>
                            { isLoaded ? Object.keys(data.business_hours).map(function(key, index) {
                                return <li key={key}><strong className="capitalize">{key}</strong><span className="uppercase"> ({data.business_hours[key].start_time} -  {data.business_hours[key].end_time} CET)</span></li>
                            }) : '(8:00 AM - 4:00 PM CET)'}
                        </ul>
                        <a href="/salesOffice/" className="border-btn cursor-pointer inline-block no-underline py-3.75 px-7.5 mt-4 bg-red-light text-center text-white rounded-3 leading-none font-semibold hover:bg-blue-dark transition-all duration-300 ease-in-out">Contact us</a>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}