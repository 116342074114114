import React from 'react';

var unirest = require('unirest');

export default function freshdesk(path) {
    var API_KEY = "ARbmO4YLkk6VcMC6bIcS";
    var FD_ENDPOINT = "blueventure";
    var URL =  "https://" + FD_ENDPOINT + ".freshdesk.com" + path;
    var Request = unirest.get(URL);
    return new Promise((resolve, reject) => {
        Request.auth({
            user: API_KEY,
            pass: "X",
            sendImmediately: true,
        })
        .end(function test(response){
            if (response.error) {
                return reject(response.error)
            }
            return resolve(response.body);
        });
    });
}