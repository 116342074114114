import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'
import freshdesk from '../freshdesk';

export default function Article() {
    const [data, setData] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const {Id} = useParams();
    useEffect(() => {
        freshdesk('/api/v2/solutions/articles/' + Id)
        .then(res => res)
        .then(
            (result) => {
                setData(result);
                setIsLoaded(true);
            },
            (error) => {
                console.log(error);
            }
        )
    }, [Id])

    let documents;
    if(isLoaded) {
        if(data.attachments.length >= 1) {
            documents = <div className="mb-2 border-t">
                        {data.attachments.map(attachment => {
                            return <a href={attachment.attachment_url} key={attachment.id} target="_blank" rel="noreferrer" className="border-solid rounded-3 border-2 border-red-light text-red-light font-semibold text-sm leading-none py-3 px-6 mt-4 mr-2 inline-block mx-auto hover:bg-blue-dark hover:text-white hover:border-blue-dark transition-all duration-300 ease-in-out"><i className="ti-download mr-2"></i>
                            {attachment.name}</a>
                        })}
            </div>
        }
    }
    return (
        <div id={data.id} className="get-help smaller-container mx-auto bg-white mb-3 px-4 mt-10">
                <span className="w-28 h-0.5 bg-gray-span block"><em className="w-12 h-0.5 block bg-red-light"></em></span>
                <h2 className="text-2xl my-5">{data.title}</h2>
                <div className="px-5 pt-5 shadow-md pb-10 mb-4">
                    <div className="pb-5" dangerouslySetInnerHTML={{ __html: data.description }}></div>
                    {documents}
                </div>
        </div>
    )
}