import React, {useEffect} from "react";
import Hero from './components/hero'
import DestinationList from './components/topFive/destinationList'
import SalesOffice from './components/salesOffice'
import HiddenGems from './components/hiddenGems/hiddenGems'
import BestFrom from './components/bestFrom'
import CallSection from './components/callSection'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default function Home() {
    useEffect(() => {
        document.title = "Blue Venture"
     }, []);
    
    return (
        <div>
            <Hero />
            <DestinationList />
            <HiddenGems />
            <SalesOffice />
            <BestFrom />
            <CallSection />
        </div>
    );
}