import React, {useState, useEffect} from "react";

import Map from './components/map'
import SalesOfficesList from './components/salesOfficesList'

export default function SalesOffice() {
     const [salesOffices, setSalesOffices] = useState([])
     const [isLoaded, setIsLoaded] = useState(false);
 
     
     useEffect(() => {
         document.title = "Sales offices - Blue Venture"
         
         var OpenLocationCode = require('open-location-code').OpenLocationCode;
         var openLocationCode = new OpenLocationCode();

        async function getSalesOffices() {
            const response = await fetch('https://blueventure.net/rest/sales/');
            const data = await response.json();
            const arrayOfObj = Object.entries(data).map((e) => ( { [e[0]]: e[1] } ));
            arrayOfObj.splice(arrayOfObj.length - 2, 2);
            arrayOfObj.forEach((element,index) => {
                element[index].PlusCode = convertPlusCode(element[index].PlusCode);
            });
            setSalesOffices(arrayOfObj);
            setIsLoaded(true);
        }
        getSalesOffices()

        function convertPlusCode(newLocation) {
            let coord = openLocationCode.decode(newLocation);
            let latLng = [coord.latitudeCenter,coord.longitudeCenter];
            return latLng;
        }
     }, [])
 

    return (
        <div id="salesOfficeContainer">
            { isLoaded ? <Map salesOffices={salesOffices}/>  : ''}
            { isLoaded ? <SalesOfficesList salesOffices={salesOffices}/>  : ''}
        </div>
    );
}