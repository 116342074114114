import React, {useState, useEffect} from "react";
import HiddenGem from "./hiddenGem"
import OwlCarousel from 'react-owl-carousel';

export default function HiddenGems() {
    const [hiddenGems, setHiddenGems] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch("https://blueventure.net/rest/countries/")
            .then(res => res.json())
            .then(
                (result) => {
                    const arrayOfObj = Object.entries(result).map((e) => ( { [e[0]]: e[1] } ));
                    arrayOfObj.splice(arrayOfObj.length - 2, 2);
                    setHiddenGems(arrayOfObj);
                    setIsLoaded(true);

                },
                (error) => {
                    setIsLoaded(false);
                    setError(error);
                }
            )
    }, [])


    const cloudimageConfig = {
        token: "aljedakxem",
        baseURL: "",
    };

    function getRandom(arr, n) {
        var result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len)
            throw new RangeError("getRandom: more elements taken than available");
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }

    if(isLoaded) {
        var gems = getRandom(hiddenGems, 10).map(gem => {
            let gemKey = Object.keys(gem);
            return <HiddenGem key={gem[gemKey].ID} {...gem[gemKey]} cloudimageConfig={cloudimageConfig} />
        });
    }

    let state = {
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1140: {
                items: 5,
            },
        },
    }

    return (
        <div className="w-full bg-gray-background">
            <div className="container mx-auto pt-15 pb-8.5 px-4">
                <span className="w-28 h-0.5 bg-gray-span block"><em className="w-12 h-0.5 block bg-red-light"></em></span>
                <h2 className="uppercase font-bold text-gray-light text-mainTitle mt-6 mb-6">Find the hidden gems</h2>
                {isLoaded ? <OwlCarousel items={5}
                    className="owl-theme"
                    margin={8}
                    responsive={state.responsive}>
                    {gems}
                </OwlCarousel> : null}
            </div >
        </div>
    )
}
