import React, {useEffect} from "react";
import SubHero from "./components/subHero"
import SecondNavbar from "../../common/secondNavbar"
import Product from "./components/product"
import ProductApi from "./productApi"

export default function Details() {
    let product = ProductApi();
    useEffect(() => {
        document.title = "Product - Blue Venture"
     }, []);
    return (
        <div>
            <SubHero data={{ product: [product]}}/>
            <SecondNavbar />
            <Product data={{ product: [product] }} />
        </div>
    );
}