import { useState, useEffect } from "react";


export default function ProductApi() {
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
            fetch("https://blueventure.net/rest/product/605")
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setItems(result["0"]);
    
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
    }, [])
    return items;
}