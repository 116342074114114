import React from "react"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import ProductDescription from "./productDescription"

import L from 'leaflet';


import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function Product(props) {
    let product = props.data.product[0];

    let descriptions = [
        {
            id: 1,
            title: "Your Experience",
            description: product.p1b1,
            uniqueClass: "your-experience"
        },
        {
            id: 2,
            title: "Accommodation",
            description: product.p2b1,
            uniqueClass: "accommodation"
        },
        {
            id: 3,
            title: "Your Course",
            description: product.p3b1,
            uniqueClass: "your-course"
        },
        {
            id: 4,
            title: "Getting there",
            description: product.p5b1,
            uniqueClass: "getting-there"
        }
    ]

    const fullProduct = descriptions.map(description => {
        return <ProductDescription key={description.id} {...description}/>
    })

    return (
        <div className="smaller-container mx-auto">
            {fullProduct}
            <div id="map" className="relative h-96">
                <MapContainer className="map absolute top-0 bottom-0" style={{ width: '100%', height: '400px' }} center={[10,10]} zoom={13} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[10,10]}>
                        <Popup>
                            A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                    </Marker>
                </MapContainer>
            </div>
            <div className="grid grid-cols-12 grid-rows-1 lg:pt-20 pt-10 px-4">
                <div className="lg:col-span-4 text-productTitle col-span-12">What's included</div>
                <div className="lg:col-span-8 lg:pl-6 col-span-12" dangerouslySetInnerHTML={{ __html: product.Included }}></div>
            </div>
        </div>
    )
}