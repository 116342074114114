import React from 'react'
import { MapContainer as LeafletMap, TileLayer, Tooltip, Circle} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { bounds } from 'leaflet';
import Flag from 'react-flagpack'

export default function SalesOffice(props) {
    return ( 
        <div className="col-span-12 md:px-2 lg:col-span-4 md:col-span-6">
            <div className="mb-5 relative h-56">
                <div id="map" className="relative single-map h-56 z-0 pointer-events-none">
                    <LeafletMap className="map absolute top-0 bottom-0 markercluster-map  pointer-events-none" style={{ width: '100%', height: '100%' }} zoom={13} center={props.PlusCode} minZoom={2} scrollWheelZoom={false}
                    maxBoundsViscosity={1.0}
                    maxBounds={bounds}
                    zoomControl={false}
                    dragging={false}
                    > 
                        <TileLayer
                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                            />
                        <Circle
                        center={props.PlusCode}
                        color={"#2563EB"}
                        pathOptions={{ fillColor: '#2563EB' }}
                        fillOpacity={1}
                        radius={35}>
                            <Tooltip opacity={1} offset={[1.5, -10]} direction="top" permanent className={'tooltip-salesoffice'}>
                                <p className="uppercase mb-0 text-xl text-white font-semibold">{props.City}</p>
                            </Tooltip>

                        </Circle>
                    </LeafletMap>
                </div>
                <div className="unsplash-images absolute top-0 z-10 h-full">
                    <img src={props.UnsplashCollectionID ? "https://source.unsplash.com/collection/" + props.UnsplashCollectionID + "/800x300" : "https://source.unsplash.com/collection/59438018/800x300"}alt="City from unsplash" className="object-cover opacity-0 h-full"/>
                </div>
            </div>
            <div>
                <p className="uppercase text-gray-nav mb-2 text-xs font-semibold">{props.Country}</p>
                <h2 className="uppercase text-gray-900 flex justify-between font-bold mb-0 text-2xl">{props.City}<Flag code={props.alpha2}/></h2>
                <p className="text-gray-600 uppercase mb-4 text-xs font-semibold">{props.Realname}</p>
            </div>
            <div>
                <p className="font-medium mb-0 text-gray-nav text-base">{props.Address}</p>
                <p className="font-medium mb-0 text-gray-nav text-base">{props.Zip} {props.City}</p>
                <p className="font-medium text-gray-nav mb-4 text-base">{props.Country}</p>

                <p className="mb-4"><a href={`tel:${props.Phone}`} className="font-medium text-gray-nav text-base">Phone: {props.Phone}</a></p>

                <a href={`https://www.google.dk/maps/place/${props.PlusCode}`} target="_blank" rel="noreferrer" className="block text-red-600 pb-2 font-semibold">Get directions<i className="ml-2 align-middle ti-arrow-right"></i></a>
                <a href={`mailto:${props.Email}`}  target="_blank" rel="noreferrer" className="block text-red-600 pb-2 font-semibold">Send email<i className="ml-2 icon_mail_alt"></i></a> 
            </div>
        </div>
    )
}