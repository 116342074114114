import React from "react"

export default function option(props) {
    return (
        <div className=" option bg-white pt-10 pb-7.5 px-7.5 h-full transform hover:-translate-y-2.5 shadow-icon">
            <i className={`text-optionIcons bg-gray-iconBackground rounded-full p-4 mb-4 text-green-price ${props.icon}`}></i>
            <h4 className="text-lg mb-2 text-gray-light font-medium">{props.name}</h4>
            <p className="text-base mb-0 text-gray-options">{props.description}</p>
        </div>
        )
}
