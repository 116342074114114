import React from 'react';
import Destination from './destination';


export default function DestinationList(props) {
    function changeLocation(pluscode) {
        props.onChange(pluscode)
    }

    let contained = [];
    props.destinations.filter((destination,index) => {
        return props.showDestinations.forEach(element => {
            if(element.id === destination[index].DestID) {
                contained.push(destination[index]);
            }
        });
    });

    const destinationsToShow = contained.map(singleDestination => {
        return <Destination key={singleDestination.DestID}  {...singleDestination} onChange={changeLocation}/>
    })
    
    const mapDestinations = props.destinations.map((singleDestination, index) => {
        return <Destination key={singleDestination[index].DestID}  {...singleDestination[index]} onChange={changeLocation}/>
    })

    return (
        <div className="mx-10">
            <div className="pt-2.5 pb-2 mt-8">
                <p className="font-semibold text-gray-nav mb-1">500+ products available</p>
                <div className="flex justify-between">
                    <h1 className="font-bold leading-none mb-0 text-gray-destination text-4xl">Destinations</h1>
                    <button className="py-1 px-2 w-10 h-8 rounded-3 border rounded-1"><i className="icon_adjust-vert text-xl"></i></button>
                </div>
            </div>
            <div className="border-b border-t">
                <p className="text-orange-500 mb-3 mt-3 font-medium">Book with confidence during COVID-19. <u>Read more</u></p>
            </div>
            <div className="mt-7.5 max-h-75 overflow-scroll">
                { (contained.length <= 0) ? mapDestinations : destinationsToShow}
            </div>
        </div>
    )
}