import React from "react"
import aboutImage from "../../../assets/about_1.jpg"

export default function Story() {
    return (
        <div className="smaller-container mx-auto pt-15 pb-8.5 px-4">
                <span className="w-28 h-0.5 bg-gray-span mx-auto block"><em className="w-12 mx-auto block h-0.5 block bg-red-light"></em></span>
                <h2 className="md:text-h3-banner text-center text-productTitle text-gray-light mt-6">Our Origins and Story</h2>
                <p className="md:text-2xl text-xl text-center leading-1.4 text-gray-lighter">We guide you to solid diving around the world</p>
            <div className="grid grid-cols-12 gap-y-5 md:gap-y-0">
                <div className="md:col-span-6 col-span-12"><img src={aboutImage} alt="About"/></div>
                <div className="md:col-span-5 md:col-start-8 col-span-12">
                    <p>Blue Venture is a dive travel agency offering a wide range of unique destinations, each carefully chosen by combining the current trends in diving and travelling in order to satisfy both above and below the water line.</p>
                    <p>Behind Blue Venture is one of the largest networks connecting some of the most experienced divers and their many years of personal experience in teaching, travelling and developing the dive industry!</p>
                    <p><em>Destination Manager, Maria Bollerup</em></p>
                </div>
            </div>
            <div className="grid grid-cols-12 md:gap-8 gap-y-2 md:gap-y-0 mt-10">
                <div className="md:col-span-4 col-span-12">
                    <h5 className="text-xl">Our dive operators</h5>
                    <p>Choosing the right partner is just as important as choosing the right destination. All our dive operators are carefully hand-picked for every single destination. We have looked at the exact location of the facility, the logistics of the diving and the management of the dive centers and combined it using criteria that mean something to us.
                    </p>
                    <p>Our partners are chosen because they are able to create a unique operation and fulfill our personal demands for high quality.</p>
                </div>
                <div className="md:col-span-4 col-span-12">
                    <h5 className="text-xl">Our Product range</h5>
                    <p>You can't expect all dives to be in crystal clear conditions, and you can't expect the marine life to be present just because you are. But you can guard yourself against disappointment by following the guide lines in our product line.
                    </p>
                    <p>All destinations are chosen because the diving at this particular location represents the apex of diving in the region. The marine life, the dive center and the general atmosphere are the key factors. We are fussy about keeping it simple and limit the products to the destinations that we know are worth traveling to.</p>
                </div>
                <div className="md:col-span-4 col-span-12">
                    <h5 className="text-xl">Our Sales Agent Network</h5>
                    <p>Our destinations are only sold through well renowned travel agents and European dive centers. It is solely decided by you, the customer, who handles your request.
                    </p>
                    <p>For example, an inexperienced traveler, but experienced diver might focus on the comfort and travel advice of a travel agency rather than the professional advice on diving from a local dive center.</p>
                    <p>At all times, the choice is yours. Make your request!</p>
                </div>
            </div>
        </div>
    )
}