import React from "react";
import { Link } from "react-router-dom";

const banner = {
    backgroundImage: `url(https://aljedakxem.cloudimg.io/v7/sandbox.blueventure.net/panagea/_assets/images/front/large/front-03.jpg?func=crop&gravity=auto&sharpen=1&h=700&w=1400)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '420px'
}

export default function SalesOffice() {
    return (
        <div>
            <section className="container mx-auto md:pt-15 pt-7.5 pb-8.5 px-4">
                <div style={banner} className="container-gradient w-full h-full relative" >
                    <div className="container z-9 mx-auto md:px-15 px-5 text-white absolute top-2/4 hero-position">
                        <small className="text-sm uppercase font-medium">Sales Offices</small>
                        <h3 className="text-h3-banner mt-3.75 font-bold">Ready to discuss booking options?</h3>
                        <p className="max-w-sm-25 text-2xl mb-7 font-normal">Contact your local sales office today</p>
                        <Link to="/salesOffice"
                            className="cursor-pointer inline-block no-underline py-3.75 px-7.5 bg-red-light text-center rounded-3 leading-none font-semibold hover:bg-blue-dark transition-all duration-300 ease-in-out">
                            Find a sales office</Link>
                    </div>
                </div>
            </section>
        </div >
    );
}