import React, {useState, useEffect} from "react"
import Aq from "./aq"
import { Link, useParams } from 'react-router-dom'
import freshdesk from '../../../freshdesk';

export default function GetHelp() {
    const [isCollapsed, setCollapse] = useState(true)
    const [data, setData] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const {Id, Name} = useParams();

    useEffect(() => {
        freshdesk('/api/v2/solutions/folders/' + Id + '/articles')
        .then(res => res)
        .then(
            (result) => {
                setData(result);
                setIsLoaded(true);
            },
            (error) => {
                console.log(error);
            }
        )
    }, [Id])
    
    const collapse = (e) => {
        let description = e.target.nextElementSibling;
        let plusIcon = e.target.children[0];
        if(!isCollapsed) {
            setCollapse(true);
            description.style.maxHeight = null;
            plusIcon.classList.remove("ti-minus")
            plusIcon.classList.add("ti-plus")
        } else {
            setCollapse(false)
            description.style.maxHeight = description.scrollHeight + "px";
            plusIcon.classList.remove("ti-plus")
            plusIcon.classList.add("ti-minus")
        }
    }

    const details = data.map(card => {
        return <Aq key={card.id} collapse={collapse} {...card}/>
    })
    
    return (
        <div className="bg-gray-background">

        <div className="smaller-container mx-auto px-4 pt-10 pb-5">
            <span className="w-28 h-0.5 bg-gray-span block"><em className="w-12 h-0.5 block bg-red-light"></em></span>
            <h2 className="text-2xl mt-6 font-semibold">{Name}</h2>
            <p className="text-base">Explore our solutions below.</p>
            { isLoaded ? details : ''}
            <Link className="rounded-3 cursor-pointer inline-block no-underline py-3.75 px-7.5 mt-4 bg-red-light text-center text-white leading-none text-lg font-semibold hover:bg-blue-dark transition-all duration-300 ease-in-out" to="/support/"><i className="ti-arrow-left text-base mr-2 align-middle"></i>Back</Link>
        </div>
        </div>
    )
}