import React, {useState,useEffect} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from "react-router-dom";

// COMMON
import NavBar from './common/navbar'
import Footer from './common/footer'
import ScrollToTop from './common/scollToTop'

// PAGES
import Home from "./pages/home/Home"
import Details from "./pages/details/Details"
import Destinations from "./pages/destinations/Destinations"
import SalesOffice from "./pages/salesOffice/SalesOffice"
import Company from "./pages/company/Company"
import Help from "./pages/help/Help.js"
import ToS from "./pages/ToS/ToS"
import GetHelp from "./pages/help/components/optionCards/helpPages/getHelp"
import Search from "./pages/help/components/search"
import OpeningHours from "./pages/help/components/openingHours" 
import MyBooking from "./pages/myBooking/MyBooking" 
import Article from "./pages/help/components/article"


export default function App() {
    const [isMap, setIsMap] = useState(false);
    const [isMyBooking, setIsMyBooking] = useState(false);
    
        useEffect(() => {
            window.onpopstate = (e) => {
                setIsMyBooking(false);
            }
        })

        return (
            <Router basename={'/'} >
                <ScrollToTop />
                {isMyBooking ? null : <NavBar setIsMap={setIsMap}/> }
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/details/">
                        <Details />
                    </Route>
                    <Route exact path="/destinations/">
                        <Destinations />
                    </Route>
                    <Route exact path="/salesOffice/">
                        <SalesOffice />
                    </Route>
                    <Route exact path="/company/">
                        <Company />
                    </Route>
                    <Route exact path="/support/">
                        <Help />
                    </Route>
                    <Route exact path="/ToS/">
                        <ToS />
                    </Route>
                    <Route exact path="/support/folder/:Id/:Name">
                        <Search title="How can we help?"/>
                        <GetHelp />
                        <OpeningHours />
                    </Route>
                    <Route exact path="/support/article/:Id/:Title">
                        <Search title="How can we help?"/>
                        <Article />
                        <OpeningHours />
                    </Route>
                    <Route exact path="/myBooking">
                        <MyBooking setIsMyBooking={setIsMyBooking}/>
                    </Route>
                </Switch>
                {isMyBooking || isMap ? null : <Footer />}      
            </Router>
    )
}