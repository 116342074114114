import React from "react";
import Img, { CloudimageProvider } from "react-cloudimage-responsive"
import imageReplacer from '../../assets/Frame-164.svg'

export default function HiddenGem(props) {
    
    return (
        <div className="gem">
            <figure className="rounded-3 relative w-full h-full overflow-hidden cursor-pointer">
                <div className="absolute text-white transform top-2/4 left-2/4 -translate-x-1/2 z-10">
                    <h3 className="text-lg">{props.Country}</h3>
                </div>
                <div className="h-80">
                {props.Image !== null ? 
                 <CloudimageProvider config={props.cloudimageConfig}>
                    <Img src={props.Image} id="hiddenGemImage" style={{height: '320px'}} ratio={0.8} alt="Hidden gem"/>
                </CloudimageProvider> : <img src={imageReplacer} alt="Replacement image"></img>}
                </div>
            </figure>
        </div>
    );
}