import React, {useState, useEffect} from "react"
import { Link } from 'react-router-dom';
import freshdesk from '../freshdesk';

export default function Faq() {
const [data, setData] = useState([])
const [isLoaded, setIsLoaded] = useState(false);
useEffect(() => {
    freshdesk('/api/v2/solutions/folders/5000034930/articles')
    .then(res => res)
    .then(
        (result) => {
            setData(result);
            setIsLoaded(true);
        },
        (error) => {
            console.log(error);
        }
    )
}, [])
    return (
        <div className="smaller-container mx-auto pt-15 pb-8.5 px-4">
            <span className="w-28 h-0.5 bg-gray-span block"><em className="w-12 h-0.5 block bg-red-light"></em></span>
            <h2 className="text-2xl mt-6 font-semibold">Frequently Asked Questions</h2>
            <p className="text-base">Get answers to the most common questions from our customers.</p>
            <div className="faq">
                <ul className="list-none cursor-pointer">
                    {data.map(faq => {
                        return <li key={faq.id} className="md:w-5/12 w-full"><Link to={`/support/folder/5000034930/FAQ`}><i className="icon_documents_alt mr-2 text-base align-middle"></i>{faq.title}</Link></li>
                    })}     
                </ul>
            </div>
        </div>
    )
}