import React, {useEffect} from "react";
import Search from "./components/search"
import OptionList from "./components/optionCards/optionList"
import OpeningHours from "./components/openingHours"
import Faq from "./components/faq"
import ScrollToTop from '../../common/scollToTop'

export default function Help() {
    useEffect(() => {
        document.title = "Support - Blue Venture"
    }, []);


    return (
        <div id="support">
            <ScrollToTop />
            <Search title="How can we help?"/>
            <OptionList />
            <Faq />
            <OpeningHours />
        </div>
    );
}