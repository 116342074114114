import React, {useEffect, useState} from "react";
import { Link } from 'react-router-dom';

export default function Hero(props) {
    const [content, setContent] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetch("https://blueventure.net/rest/page/home")
            .then(res => res.json())
            .then(
                (result) => {
                    setContent(result.content['block-hero'][1])
                    setIsLoaded(true);

                },
                (error) => {
                    setIsLoaded(false);
                    setError(error);
                }
            )
    }, [])

    return (
            <section className="hero-image bg-cover bg-no-repeat bg-center" style={{ backgroundImage: 'url(' + content.imageLink +')'}}>
                <div className="sub-container-gradient w-full h-full relative" >
                    <div className="container mx-auto px-4 text-white absolute top-2/4 hero-position">  
                        <small className="text-sm uppercase font-medium">{content.Lead}</small>
                        <h3 className="hero-h3 uppercase font-bold">{content.Title}</h3>
                        <p className="max-w-sm-25 text-2xl mb-7 font-normal">{content.Subtitle}</p>
                        <Link to={content.Link || '/'}
                            className="cursor-pointer inline-block no-underline py-3.75 px-7.5 bg-red-light text-center rounded-3 leading-none font-semibold hover:bg-blue-dark transition-all duration-300 ease-in-out">Find
                            your next destiantion</Link>
                    </div>
                </div>
            </section>
    );
}
