import React, { useEffect } from "react";
import { MapContainer as LeafletMap, TileLayer, Marker, Popup, useMap, useMapEvents, ZoomControl} from 'react-leaflet';
import Img, { CloudimageProvider } from "react-cloudimage-responsive"
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import L from 'leaflet';

import icon from '../../../assets/svg/map-pin.svg';
import iconRed from '../../../assets/svg/map-pin-red.svg';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
// import expand from "../../../assets/svg/expand.svg"
// import { LeafletConsumer } from "react-leaflet";


export default function MapOfDestinations(props) {
    // Defining corners so user cant drag map out of view
    const corner1 = L.latLng(-90, -200)
    const corner2 = L.latLng(90, 200)
    const bounds = L.latLngBounds(corner1, corner2)

    let DefaultIcon = L.icon({
        iconSize: [25,41],
        iconAnchor: [12.5,41],
        iconUrl: icon,
        shadowUrl: iconShadow,
    });

    var LeafIcon = L.Icon.extend({
        iconSize: [25,41],
        iconAnchor: [12.5,41],
        shadowUrl: iconShadow,
    })
    
    let redIcon = new LeafIcon({iconUrl: iconRed, shadowUrl: iconShadow});
    
    L.Marker.prototype.options.icon = DefaultIcon;
    
    const cloudimageConfig = {
        token: "aljedakxem",
        baseURL: "",
        lasyLoad: false
    };

    function sortDestinations(map) {
        var contained = [];
        map.eachLayer(function(layer) {
            if( layer instanceof L.Marker && !(layer instanceof L.MarkerCluster) && map.getBounds().contains(layer.getLatLng()) ) {
                contained.push(layer.options);
            }
            if( layer instanceof L.MarkerCluster && map.getBounds().contains(layer.getLatLng()) ) {
                const groups = layer.getAllChildMarkers();
                groups.forEach(element => {
                    contained.push(element.options);
                });
            }
        });
        return contained
    }

    function SortAllDestinations() {
        const map = useMap()
        useMapEvents({
            dragend() {
                let sortedList = sortDestinations(map);
                props.setDestination(sortedList);
            },
            zoomend() {
                // setTimeout to be sure the list will be sorted after the changes of viewport
                const timer = setTimeout(() => {
                    let sortedList = sortDestinations(map);
                    props.setDestination(sortedList);
                    clearTimeout(timer);
                }, 0.1);
            }
        });
        return null
    }

    function SetDestinationView() {
      const map = useMap()
      useEffect(() => {
          if(props.location[0] !== props.previousLocation[0] && props.location[1] !== props.previousLocation[1])
          {
                map.setView(props.location,13);
                props.onNewLocation(props.location)
          }
      },[map])
      return null;
  } 

    function CustomMarker() {
        return props.destinations.map((location,index) => (
            <Marker position={location[index].PlusCode}
            key={location[index].DestID}
            id={location[index].DestID}
            className={'marker'}
            eventHandlers={{
                click: () => {
                    props.onChange(location[index].PlusCode);
                },
                popupopen: (e) => {
                    e.target.setIcon(redIcon)
                },
                popupclose: (e) => {
                    e.target.setIcon(DefaultIcon)
                }
            }}
           > 
            <Popup
                width={240}
                maxHeight={270}
                className="popup"
                closeButton={true}
                >
                    <CloudimageProvider config={cloudimageConfig}>
                        <Img src={location[index].image} style={{}} ratio={1} params="func=crop&gravity=auto&w=240&h=140&sharpen=1" alt="Destination Images"/>
                    </CloudimageProvider>
                    
                    <div className="px-5 pt-5 pb-3">
                        <h3 className="text-base mb-0 text-red-destination">{location[index].Destination}</h3>
                    </div>
                    <div className="px-5 pt-2 pb-3">
                        <a href={`https://www.google.dk/maps/place/${location[index].PlusCode}`} target="_blank" rel="noreferrer"><p className="text-red-destination pb-2 font-semibold"><i className="icon_compass mr-3 align-middle"></i>Get directions</p></a>
                        <p className="text-red-destination pb-2 font-semibold"><i className="icon_phone mr-3 align-middle"></i>{location[index].Phone}</p>
                    </div>
                </Popup>   
            </Marker>    
        ))
      }

    // L.Control.Expand = L.Control.extend({
    //     options: {
    //         position: 'topleft',
    //     },
    //     onAdd: function(map) {
    //         var button = L.DomUtil.create('button');
    //         button.className = "bg-white p-3.75 rounded-3 text-sm cursor-pointer"
    //         button.style.backgroundImage = "url('" + expand + "')";
    //         button.id = "expandButton"
    //         button.style.backgroundRepeat = "no-repeat"
    //         button.style.backgroundPosition = "center center"
    //         return button;
    //     },
    // });

        return (     
            <div id="destinationMap" className="relative h-screen z-9">
                <LeafletMap id="map" className="map absolute top-0 bottom-0 markercluster-map" style={{ width: '100%', height: '100vh' }} center={[10,10]} zoom={2} minZoom={2} scrollWheelZoom={true}
                maxBoundsViscosity={1.0}
                maxBounds={bounds}
                zoomControl={false}
                >  
                    <ZoomControl position={'topright'} />
                    <SortAllDestinations />
                    <SetDestinationView />
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        />
                    <TileLayer
                        url='https://grid.plus.codes/grid/tms/{z}/{x}/{y}.png'
                        attribution= 'grid by <a href="https://plus.codes">plus codes</a>'
                        tms={true}
                        opacity={0}
                        >
                    </TileLayer>
                    <MarkerClusterGroup maxClusterRadius={100} showCoverageOnHover={false}>
                        <CustomMarker >
                        </CustomMarker>
                    </MarkerClusterGroup>
                </LeafletMap>
            </div>
    )
}