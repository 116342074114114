import React from "react";
import SubImage from "../../../assets/subhero.jpg"

export default function Subhero(props) {

    let product = props.data.product[0];

    return (
        <section className="bg-cover bg-no-repeat bg-center h-100" style={{ backgroundImage: "url(" + SubImage + ")" }}>
            <div className="container-gradient w-full h-full" >
                <div className="smaller-container mx-auto h-100">
                    <div className="text-white grid grid-cols-12 grid-rows-1 lg:pt-32 pt-20 px-4">
                        <div className="lg:col-span-4 uppercase col-span-12">
                            <span>{product.Region}</span>
                            <h3 className="font-extrabold lg:text-subHeroHeader text-mainTitle">{product.Destination}</h3>
                            <h3 className="font-medium text-xl">{product.Country}</h3>
                        </div>
                        <div className="lg:col-span-8 lg:pl-6 mt-4 lg:mt-0 col-span-12">
                            <span className="uppercase">{product.ProductType}</span>
                            <h2 className="lg:text-h3-banner uppercase text-productTitle">{product.Product}</h2>
                            <p className="lg:text-xl text-base">{product.Comments}</p>
                            <ul className="list-none">
                                <li className="inline-block pr-10"><i className="pe-7s-clock text-2xl"></i><p>Duration<br /> {product.DaysNights}</p></li>
                                <li className="inline-block pr-10"><i className="pe-7s-plane text-2xl"></i><p>Airport<br /> {product.NearestAirport}</p></li>
                                <li className="inline-block"><i className="pe-7s-sun text-2xl"></i><p>Season<br /> {product.Season}</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}