import React, {useState, useRef, useEffect} from "react";
import logo from "../assets/svg/logo-dark.svg";
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ArrowNarrowRight } from "heroicons-react";
import ReactGA from 'react-ga';


export const initGA = () => {
    ReactGA.initialize('UA-37038682-3');
}

export const logPageView = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
}

export default function Navbar(props) {
    const [isExpanded, setExpanded] = useState(false)
    const location = useLocation();
    const menuClosed = useRef(null);
    const menuOpen = useRef(null)

    useEffect(() => {
        if(location.pathname === '/destinations') {
            props.setIsMap(true);
        }  else {
            props.setIsMap(false);
        }
        if(isExpanded) {
            setExpanded(false);
            handleToggle()
        }
        initGA();
        logPageView()
    }, [location])
    
    const handleToggle = () => {
        if(!isExpanded) {
            document.querySelector("#mobile-menu").classList.remove('hidden')
            menuClosed.current.style.display = "none"
            menuOpen.current.style.display = "block"
            setExpanded(true)
        } else {
            document.querySelector("#mobile-menu").classList.add('hidden')
            menuClosed.current.style.display = "block"
            menuOpen.current.style.display = "none"
            setExpanded(false)
        }
    }
    return (
        <nav className={location.pathname !== "/destinations" ? "bg-white fixed w-full z-50 shadow" : "bg-white block w-full z-50 shadow"}>
            <div className="container mx-auto px-4">
                <div className="flex justify-between h-14">
                        <div className="flex-shrink-0 flex items-center">
                            <NavLink to="/"><img src={logo} className="block: h-6 w-auto" alt="Blueventure logo"/></NavLink>
                        </div>
                        <div className="hidden md:ml-6 md:flex md:text-xs lg:text-sm">
                                 <NavLink exact activeClassName="current-link-desktop" className="border-transparent mr-2 hover:border-gray-300 px-1 inline-flex items-center border-b-4 text-gray-500 font-semibold" to="/">Home</NavLink>
                                <NavLink activeClassName="current-link-desktop" className="border-transparent mr-2 hover:border-gray-300 px-1 inline-flex items-center border-b-4 text-gray-500 font-semibold" to="/destinations">Destinations</NavLink>
                                <NavLink activeClassName="current-link-desktop" className="border-transparent mr-2 hover:border-gray-300 px-1 inline-flex items-center border-b-4 text-gray-500 font-semibold" to="/salesOffice">Sales offices</NavLink>
                                 <NavLink activeClassName="current-link-desktop" className="border-transparent mr-2 hover:border-gray-300 px-1 inline-flex items-center border-b-4 text-gray-500 font-semibold" to="/company">Company</NavLink>
                                 <NavLink activeClassName="current-link-desktop" className="border-transparent mr-2 hover:border-gray-300 px-1 inline-flex items-center border-b-4 text-gray-500 font-semibold" to="/support">Support</NavLink>
                            
                        </div>
                        <div className="hidden md:block self-center">
                                <Link className="bg-blue-600 text-white font-semibold md:text-xs lg:text-sm leading-none border-btn py-2 px-2 lg:px-4 hover:bg-blue-dark hover:text-white hover:border-blue-dark transition-all duration-300 ease-in-out" to="/myBooking/">My booking<ArrowNarrowRight className="inline-block ml-2 align-middle" size={14}/></Link>
                        </div>
                    {/* Mobile menu button */}
                    <div className="-mr-2 flex items-center md:hidden">
                        <button type="button" onClick={() => handleToggle()} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-inset focus:ring-indigo-500" aria-controls="mobile-menu" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            {/* Icon when menu is closed */}
                            <svg ref={menuClosed} className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                            {/* Icon when menu is open */}
                            <svg ref={menuOpen} className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#2563EB" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {/* Mobile menu */}
            <div className="hidden md:hidden h-screen" id="mobile-menu">
                <div className="pt-2 pb-3 space-y-1">
                    <ul className="md:flex items-center lg:space-x-4 font-semibold text-sm">
                        <NavLink exact activeClassName="current-link" className="border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium transition" to="/">Home</NavLink>
                        <NavLink activeClassName="current-link" className="border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium transition" to="/destinations">Destinations</NavLink>
                        <NavLink activeClassName="current-link" className="border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium transition" to="/salesOffice">Sales offices</NavLink>
                        <NavLink activeClassName="current-link" className="border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium transition" to="/company">Company</NavLink>
                        <NavLink activeClassName="current-link" className="border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium transition" to="/support">Support</NavLink>
                    </ul>
                </div>
                <div className="pt-4 pb-3 pr-4 pl-4 border-t border-b border-gray-200">
                    <Link className="bg-blue-600 block text-white font-semibold text-center text-sm leading-none border-btn py-3 hover:bg-blue-dark hover:text-white hover:border-blue-dark transition-all duration-300 ease-in-out" to="/myBooking/">My booking<ArrowNarrowRight className="inline-block ml-2 align-middle" size={14}/></Link>
                </div>
                <div className="pt-2 pb-3 space-y-1">
                    <ul className="md:flex items-center lg:space-x-4 font-semibold text-sm">
                        <NavLink exact activeClassName="current-link" className="border-transparent hover:bg-gray-50 hover:border-gray-300 hover-text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-sm font-medium transition" to="/ToS">Terms and conditions</NavLink>
                        <NavLink exact activeClassName="current-link" className="border-transparent hover:bg-gray-50 hover:border-gray-300 hover-text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-sm font-medium transition" to="">Privacy &amp; Cookies</NavLink>
                        
                    </ul>
                </div>
            </div>
        </nav>
    )
}