import React from "react";

export default function BestFrom() {
    return (
        <div className="bg-gray-background w-full md:pt-20 md:pb-12 pt-10 pb-6">
            <div className="search-container mx-auto text-center">
                <span className="w-28 h-0.5 bg-gray-span mx-auto block"><em className="w-12 mx-auto block h-0.5 block bg-red-light"></em></span>
                <h2 className="md:text-h3-banner text-productTitle text-gray-light mt-6">Best from our destiantions</h2>
                <p className="md:text-2xl text-xl leading-1.4 text-gray-lighter">Get inspired for your next trip</p>
            </div>
        </div>
    )
}

