import React, {useState, useEffect} from "react";
import Destination from "./destination";
import StaffPicks from "./staffPicks";
import { Link } from 'react-router-dom';

import OwlCarousel from 'react-owl-carousel';


export default function DestinationList() {
    const [destination, setDestination] = useState([])
    const [month, setMonth] = useState()
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch("https://blueventure.net/rest/destinations/popular/10")
            .then(res => res.json())
            .then(
                (result) => {
                    const arrayOfObj = Object.entries(result).map((e) => ( { [e[0]]: e[1] } )).slice(0,result['size']);
                    
                    setDestination(arrayOfObj);

                    setMonth(result['month_name']);
                    setIsLoaded(true);

                },
                (error) => {
                    setIsLoaded(false);
                    setError(error);
                }
            )
    }, [])

    const cloudimageConfig = {
        token: "aljedakxem",
        baseURL: "https://aljedakxem.cloudimg.io/v7/stage.blueventure.net/_assets/images/signature/destinations/",
    };
    

    const staffDestinations = [
        {
            id: 1,
            title: 'Learn to dive - Upgrade Accom',
            location: 'Egypt Dahab',
            background: cloudimageConfig.baseURL + "thailand-koh-tao-sairee.jpg?func=crop&gravity=auto&sharpen=1&h=270&w=400",
            price: 630,
        },
        {
            id: 2,
            title: 'Learn to dive extended - dorm',
            location: 'Thailand Koh Tao - Sairee Beach',
            background: cloudimageConfig.baseURL + "thailand-koh-tao-chalok.jpg?func=crop&gravity=auto&sharpen=1&h=270&w=400",
            price: 454,
        },
        {
            id: 3,
            title: 'Learn to dive',
            location: 'Indonesia Gili Air',
            background: cloudimageConfig.baseURL + "thailand-koh_lipe.jpg?func=crop&gravity=auto&sharpen=1&h=270&w=400",
            price: 571,
        },
        {
            id: 4,
            title: 'Learn to dive - no accommodation',
            location: 'Thailand Koh Tao - Chalok Bay',
            background: cloudimageConfig.baseURL + "maldives-maafushi.jpg?func=crop&gravity=auto&sharpen=1&h=270&w=400",
            price: 342,
        },
    ];

    const destinations = destination.map((singleDestination, index) => {
        return <Link rel="preload" to="/" key={index}><Destination key={index} number={index + 1}  {...singleDestination[index]}/></Link>
    })

    const staffPicks = staffDestinations.map(picks => {
        return <Link rel="preload" to="/details" key={picks.id}><StaffPicks key={picks.id} {...picks} /></Link>
    });

    let state = {
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1140: {
                items: 5,
            },
        },
    }
    return (
        <div className="container mx-auto md:pt-15 pt-7.5 pb-8.5 px-4">
            <div className="mb-15">
                <div className="mt-6">
                    <h2 className="font-bold uppercase leading-tight text-mainTitle text-gray-light">TOP 10 DESTINATIONS, {month}</h2>
                    <p className="text-gray-lighter">Ranked by customer bookings in {month}</p>
                </div>
                <OwlCarousel items={5}
                    className="owl-theme"
                    margin={8}
                    responsive={state.responsive}>
                    {destinations}
                </OwlCarousel>
            </div>
            <div>
                <div className="mt-6">
                    <h2 className="font-bold uppercase leading-tight text-mainTitle text-gray-light">Staff picks - learn to dive</h2>
                    <p className="text-gray-lighter">if we had to start today, we would go here!</p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-7 mb-15">
                    {staffPicks}
                </div>
            </div>
            <p className="text-center my-7.5">
                <Link to="/destinations" className="uppercase border-solid border-2 border-red-light text-red-light font-semibold text-sm leading-none border-btn py-3 px-6 mx-auto hover:bg-blue-dark hover:text-white hover:border-blue-dark transition-all duration-300 ease-in-out">Explore All Courses</Link>
            </p>
        </div>
    )
}