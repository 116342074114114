import React, {useEffect} from "react";
import SubHero from "../../common/subHero"
import OptionList from "./components/optionCards/optionList"
import Story from "./components/story"
import Divers from "./components/divers/diverList"

export default function Company() {
    useEffect(() => {
        document.title = "Company - Blue Venture"
     }, []);
    return (
        <div>
            <SubHero title="About us"/>
            <OptionList />
            <Story />
            <div className="bg-gray-background w-full md:pt-20 md:pb-12 pt-10 pb-6">
                <div className="search-container mx-auto text-center px-4">
                    <span className="w-28 h-0.5 bg-gray-span mx-auto block"><em className="w-12 mx-auto block h-0.5 block bg-red-light"></em></span>
                    <h2 className="md:text-h3-banner text-productTitle text-gray-light mt-6">Our Core Team</h2>
                    <p className="md:text-2xl text-xl text-gray-lighter">A cross-functional team of dive professionals<br/>with +40 years of experience in the travel industry</p>
                </div>
            </div>
            <Divers />
        </div>
    );
}